import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import A11yButton from '@/components/a11y-button/A11yButton';
import TileActions from '@/components/tile/TileActions';
import TileCheckmark from '@/components/tile/TileCheckmark';
import TileImage from '@/components/tile/TileImage';
import TileTextContainer from '@/components/tile/TileTextContainer';
import useDevice from '@/hooks/useDevice';
import { debounce, isInView } from '@/utils/helper';

import styles from './styles.module.scss';
import TileGenres from './TileGenres';

const cx = classNames.bind(styles);

const Tile: React.FC<TileProps> = ({
    id,
    slug,
    type,
    title,
    shareUrl,
    numberOfLines = 2,
    subTitle,
    summary,
    image,
    website,
    spotifyUrl,
    height = 'full',
    size = 'default',
    style = 'box',
    active = false,
    disabled = false,
    checked = false,
    showChecked = false,
    actionsVisible = true,
    genresVisible = true,
    customActions,
    aside,
    genres,
    busy = false,
    showOnScroll = false,
    festivals,
    getFestivalUrl,
    getTagUrl,
    onPress,
}) => {
    const container = useRef<HTMLDivElement>(null);
    const [visible, isVisible] = useState(false);
    const { isMobile, isTablet } = useDevice();

    const checkVisibility = useCallback(async () => {
        if (container.current) {
            const visible = await isInView(container.current);
            if (visible) isVisible(visible);
        }
    }, []);

    useEffect(() => {
        const viewportListener = debounce(checkVisibility, 200, null);
        let parentElement;

        (async () => {
            if (typeof window !== 'undefined') {
                if (showOnScroll) {
                    const tileContainer = container.current;

                    parentElement =
                        tileContainer?.closest('[class*="__slides"]') ||
                        tileContainer?.closest('[class*="custom__scrollbar"]');

                    if (parentElement) {
                        parentElement.addEventListener(
                            'scroll',
                            viewportListener
                        );
                    }
                }

                document.addEventListener('scroll', viewportListener);
                window.addEventListener('resize', viewportListener);
                window.addEventListener('orientationchange', viewportListener);

                await checkVisibility();
            }
        })();

        return () => {
            document.removeEventListener('scroll', viewportListener);
            window.removeEventListener('resize', viewportListener);
            window.removeEventListener('orientationchange', viewportListener);

            if (parentElement) {
                parentElement.removeEventListener('scroll', viewportListener);
            }
        };
    }, [showOnScroll, checkVisibility]);

    return (
        <A11yButton enabled={!busy} active={active} height={height}>
            <div
                ref={container}
                className={cx(
                    'tile',
                    `tile--${type}`,
                    `tile--size-${size}`,
                    `tile--style-${style}`,
                    `tile--height-${height}`,
                    { 'tile--active': active },
                    { 'tile--disabled': disabled },
                    { 'tile--checked': checked && showChecked },
                    { 'tile--unchecked': !checked && showChecked },
                    { 'tile--has-aside': aside },
                    { 'tile--enabled': !busy },
                    `tile--title-clamp-${numberOfLines}`
                )}>
                <TileImage
                    isMobile={isMobile}
                    type={type}
                    alt={type !== 'media' ? (title ?? '') : (summary ?? '')}
                    size={size}
                    src={image}
                    busy={busy || !visible}
                />
                {type !== 'media' ? (
                    <div className={cx('__content')}>
                        <div className={cx('__left')}>
                            <TileTextContainer
                                id={id}
                                type={type}
                                title={title}
                                subTitle={subTitle}
                                summary={summary}
                                isMobile={isMobile || isTablet}
                                busy={busy || !visible}
                                festivals={festivals}
                                getFestivalUrl={getFestivalUrl}
                            />
                            {genresVisible && (
                                <TileGenres
                                    id={id}
                                    type={type}
                                    genres={genres}
                                    busy={busy || !visible}
                                    getTagUrl={getTagUrl}
                                />
                            )}
                            {actionsVisible && (
                                <TileActions
                                    id={id ?? ''}
                                    title={title ?? ''}
                                    slug={slug ?? ''}
                                    type={type}
                                    ticketUrl={website}
                                    spotifyUrl={spotifyUrl}
                                    shareUrl={shareUrl}
                                    ariaLabel={title ?? ''}
                                    busy={busy || !visible}
                                />
                            )}
                            {customActions && (
                                <div className={cx('__custom-actions')}>
                                    {customActions}
                                </div>
                            )}
                        </div>
                        {aside && style === 'box' && !busy && visible ? (
                            <div className={cx('__aside')}>
                                <div className={cx('__aside-content')}>
                                    {aside}
                                </div>
                                {showChecked ? (
                                    <div
                                        className={cx('__checkmark-container')}>
                                        <TileCheckmark
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {aside && style === 'row' && !busy && visible ? (
                    <div className={cx('__aside')}>
                        <div
                            className={cx('__aside-content', {
                                '--has-checkbox': showChecked,
                            })}>
                            {aside}
                        </div>
                        {showChecked ? (
                            <div className={cx('__checkmark-container')}>
                                <TileCheckmark
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
                <button
                    onClick={() => onPress()}
                    className='sr-only'
                    data-expand-click-area>
                    {`View ${type} details`}
                </button>
            </div>
        </A11yButton>
    );
};

export default Tile;
