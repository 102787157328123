import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';

interface Props {
    title: string;
    suffixTitle?: boolean;
    description?: string;
    appendDefaultDescription?: boolean;
    image?: string;
    schema?: string;
}

const SeoHead = ({
    title,
    suffixTitle = true,
    description,
    appendDefaultDescription = false,
    image = 'https://festgps.app/social-sharing.jpg',
    schema,
}: Props) => {
    const pageTitle = `${title} ${suffixTitle ? '| FestGPS: Music Festival Finder' : ''}`;
    const router = useRouter();

    const getDescription = () => {
        const defaultDescription = `FestGPS music festival finder, is the the world's greatest music festival app. Integrating with streaming service data to instantly show the best festivals, flights and accommodations for each user, FestGPS is the ultimate music festival recommendation engine.`;

        if (description) {
            return !appendDefaultDescription
                ? description
                : `${description} ${defaultDescription}`;
        }

        return defaultDescription;
    };

    const getJSONSchema = () => {
        return `[{
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "FestGPS: Music Festival Finder",
    "operatingSystem": "IOS,ANDROID",
    "description":"Never miss a beat with FestGPS the ultimate music festival recommendation engine",
    "applicationCategory": "Events",
    "offers": {
        "@type": "Offer",
        "price": "0"
    }
},{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "FestGPS: Music Festival Finder",
    "url": "https://festgps.app/",
    "logo": "https://festgps.app/images/global/festgps-h.png",
    "sameAs": [
        "https://www.instagram.com/festgps/",
        "https://www.facebook.com/FestGPS",
        "https://twitter.com/FestGPS"
    ]
}${schema ? `,${schema}` : ''}]`;
    };

    return (
        <>
            <Head>
                <title key='title'>{pageTitle}</title>
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <meta
                    key='referrer'
                    name='referrer'
                    content='strict-origin-when-cross-origin'
                />
                <meta
                    key='description'
                    name='description'
                    content={getDescription()}
                />
                <meta
                    key='fb:app_id'
                    property='fb:app_id'
                    content={
                        process?.env?.FB_APP_ID ??
                        process?.env?.NEXT_PUBLIC_FB_APP_ID
                    }
                />
                <meta key='og:title' property='og:title' content={pageTitle} />
                <meta
                    key='og:url'
                    property='og:url'
                    content={`${process?.env?.APP_ORIGIN ?? process?.env?.NEXT_PUBLIC_APP_ORIGIN}${router?.asPath}`}
                />
                <meta key='og:image' property='og:image' content={image} />
                <meta
                    key='og:image:url'
                    property='og:image:url'
                    content={image}
                />
                <meta
                    key='og:image:width'
                    property='og:image:width'
                    content='1024'
                />
                <meta
                    key='og:image:height'
                    property='og:image:height'
                    content='500'
                />
                <meta
                    key='og:description'
                    property='og:description'
                    content={getDescription()}
                />
                <meta key='og:locale' property='og:locale' content='en_US' />
                <meta key='og:type' property='og:type' content='website' />
                <link
                    rel='icon'
                    type='image/png'
                    sizes='32x32'
                    href='/images/global/favicon.png'
                    key='favicon'
                />
                <meta
                    key='twitter:card'
                    name='twitter:card'
                    content='summary'
                />
                <meta
                    key='twitter:site'
                    name='twitter:site'
                    content='@FestGPS'
                />
                <meta
                    key='twitter:creator'
                    name='twitter:creator'
                    content='@FestGPS'
                />
                <meta
                    key='twitter:title'
                    name='twitter:title'
                    content={pageTitle}
                />
                <meta
                    key='twitter:description'
                    name='twitter:description'
                    content={getDescription()}
                />
                <meta
                    key='twitter:image'
                    name='twitter:image'
                    content={image}
                />
                {process.env.NEXT_PUBLIC_APP_ENV !== 'production' ? (
                    <meta
                        name='robots'
                        content='noindex, nofollow'
                        key='robots'
                    />
                ) : null}
                {!router?.asPath.includes('/user/') && (
                    <link
                        rel='canonical'
                        href={`${process?.env?.APP_ORIGIN ?? process?.env?.NEXT_PUBLIC_APP_ORIGIN}${router?.asPath}`}
                    />
                )}
            </Head>
            <Script
                id='structured-data'
                key='structured-data'
                type='application/ld+json'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                    __html: getJSONSchema(),
                }}></Script>
        </>
    );
};

export default SeoHead;
