import classNames from 'classnames/bind';

import Icon, { IconSize, IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    isMobile: boolean;
    isTablet: boolean;
}

const TileCheckmark: React.FC<Props> = ({ isMobile, isTablet }) => {
    return (
        <div className={cx('__checkmark')}>
            <Icon
                name={IconType.CheckmarkRounded}
                size={!isMobile && !isTablet ? IconSize.Small : IconSize.Tiny}
            />
        </div>
    );
};

export default TileCheckmark;
