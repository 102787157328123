import $http from '@/utils/http';
import { parseError } from '@/utils/apiErrorHandler';
import { AppStore } from '@/store/index';
import { addUserFestival, setUserFestivals } from '@/store/user';
import { setToastData, setToastOpen } from '@/store/app';

export const isFollowingFestival = (userFestivals, id: string | number) => {
    return userFestivals?.find((f) => f.id === id);
};

export const toggleFollowFestival = async (
    userFestivals: Festival[],
    id: number | string,
    dispatch: AppStore['dispatch']
) => {
    const exists = userFestivals?.find((f) => f.id === id);

    if (!exists) {
        await followFestival(userFestivals, id, dispatch);
    } else {
        await unfollowFestival(userFestivals, id, dispatch);
    }
};

export const followFestival = async (
    userFestivals: Festival[],
    id: number | string,
    dispatch: AppStore['dispatch']
) => {
    try {
        const festival = await $http.post({
            endpoint: '/festivals/follow',
            params: {
                festivalId: id,
            },
        });

        const exists = userFestivals?.find((f) => f.id === id);
        if (!exists) dispatch(addUserFestival(festival));

        (document.activeElement as HTMLElement).blur();

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                message: 'Added to your Favorites',
            })
        );
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};

export const unfollowFestival = async (
    userFestivals: Festival[],
    id: number | string,
    dispatch: AppStore['dispatch']
) => {
    try {
        await $http.remove({
            endpoint: '/festivals/follow',
            params: {
                festivalId: id,
            },
        });

        const festivals = userFestivals.filter((f) => f.id !== id);
        dispatch(setUserFestivals(festivals));

        (document.activeElement as HTMLElement).blur();

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                message: 'Removed from your Favorites',
            })
        );
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};
