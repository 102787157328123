import classNames from 'classnames/bind';

import Icon, { IconSize, IconType } from '@/components/icon/Icon';
import SmartImage from '@/components/image/SmartImage';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    type: ListItemType;
    alt: string;
    src?: string;
    busy?: boolean;
    size: ListItemSize;
    isMobile: boolean;
}

const TileImage: React.FC<Props> = ({
    type,
    alt,
    size,
    src,
    isMobile,
    busy = false,
}) => {
    const NoImageIcon = () => {
        switch (type) {
            case 'festival':
                return (
                    <Icon
                        name={IconType.PartyPopper}
                        size={
                            isMobile || size === 'small'
                                ? IconSize.XLarge
                                : IconSize.XXLarge
                        }
                    />
                );
            case 'artist':
                return (
                    <Icon
                        name={IconType.Artist}
                        size={
                            isMobile || size === 'small'
                                ? IconSize.XLarge
                                : IconSize.XXLarge
                        }
                    />
                );
        }
    };

    return (
        <div className={cx('__image-container')}>
            {src ? (
                <SmartImage
                    src={src}
                    alt={alt}
                    imgClassName={cx('__image-elem')}
                    width={0}
                    height={0}
                    sizes='100vw'
                    quality={60}
                    circle={type === 'artist'}
                    busy={busy}
                />
            ) : (
                !src &&
                !busy && (
                    <div className={cx('__image-placeholder')}>
                        <div className={cx('__icon-container')}>
                            <NoImageIcon />
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default TileImage;
