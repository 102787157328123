import $http from '@/utils/http';
import { parseError } from '@/utils/apiErrorHandler';
import {
    addCrateFestival,
    setCrateFestivals,
    updateCrateFestival,
} from '@/store/user';
import { setToastOpen, setToastData } from '@/store/app';
import { AppStore } from '@/store/index';

interface ToggleCompareArgs {
    crateId: number;
    festival: Festival;
    compare: boolean;
    dispatch: AppStore['dispatch'];
}

export const toggleCompare = async ({
    festival,
    crateId,
    compare,
    dispatch,
}: ToggleCompareArgs): Promise<void> => {
    try {
        await $http.put({
            endpoint: `/crate/update`,
            params: {
                crateId,
                compare,
            },
        });

        (document.activeElement as HTMLElement).blur();

        dispatch(updateCrateFestival({ festival, changes: { compare } }));
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};

interface ToggleCrateArgs {
    id: number | string;
    userCrate: Festival[];
    dispatch: AppStore['dispatch'];
}

export const toggleAddToCrate = async ({
    id,
    userCrate,
    dispatch,
}: ToggleCrateArgs): Promise<void> => {
    const exists = userCrate.find((f) => f.id === id);

    if (!exists) {
        await addToCrate({ id, userCrate, dispatch });
    } else {
        await removeFromCrate({ id, userCrate, dispatch });
    }
};

export const addToCrate = async ({
    id,
    userCrate,
    dispatch,
}: ToggleCrateArgs) => {
    try {
        const festival = await $http.post({
            endpoint: '/crate/add',
            params: {
                festivalId: id,
            },
        });

        const exists = userCrate.find((f) => f.id === id);

        if (!exists) dispatch(addCrateFestival(festival));

        (document.activeElement as HTMLElement).blur();

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                message: 'Added to Compare',
            })
        );
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};

export const removeFromCrate = async ({
    id,
    userCrate,
    dispatch,
}: ToggleCrateArgs): Promise<void> => {
    try {
        await $http.remove({
            endpoint: '/crate/delete',
            params: {
                festivalId: id,
            },
        });

        const festivals = userCrate.filter((f) => f.id !== id);
        dispatch(setCrateFestivals(festivals));

        (document.activeElement as HTMLElement).blur();

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                message: 'Removed from Compare',
            })
        );
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};
