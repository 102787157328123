import React from 'react';
import classNames from 'classnames/bind';

import More from '@/components/more/More';
import Pill from '@/components/pill/Pill';
import Skeleton from '@/components/loading/skeleton/Skeleton';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    id?: string | number;
    busy?: boolean;
    type: ListItemType;
    genres?: Genre[];
    getTagUrl?: (genre: Genre) => string;
}

const TileGenres: React.FC<Props> = ({ id, type, busy, genres, getTagUrl }) => {
    return !busy && genres?.length && getTagUrl ? (
        <div className={cx('__genres')}>
            <More
                ariaLabel='View more related genres'
                buttonLabel='Related genres'
                title='Related Genres'>
                {genres.map((g) => (
                    <div
                        key={`${type}-${id}-genre-${g.slug}`}
                        className={cx('__genre')}>
                        <Pill
                            label={g.name}
                            size='tiny'
                            theme='black'
                            href={getTagUrl(g)}
                        />
                    </div>
                ))}
            </More>
        </div>
    ) : busy ? (
        <div className={cx('__genres')}>
            <Skeleton
                bones={[{ customClass: 'mt-1.5 h-5 w-32 md:mt-2.5' }]}
                theme='light'
            />
        </div>
    ) : (
        <div className={cx('__genres')}>&nbsp;</div>
    );
};

export default React.memo(TileGenres);
