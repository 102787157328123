import React from 'react';
import classNames from 'classnames/bind';
import Link from 'next/link';

import More from '@/components/more/More';
import Skeleton from '@/components/loading/skeleton/Skeleton';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    id?: string | number;
    busy?: boolean;
    type: ListItemType;
    festivals: Festival[];
    getFestivalUrl: (festival: Festival) => string;
}

const TileFestivals: React.FC<Props> = ({
    id,
    type,
    busy,
    festivals,
    getFestivalUrl,
}) => {
    return !busy ? (
        <div className={cx('__festivals')}>
            <More
                ariaLabel='View upcoming festivals'
                buttonLabel='Upcoming festivals'
                title='Upcoming Festivals'>
                {festivals.map((f, idx) => (
                    <div
                        key={`${type}-${id}-festival-${f.slug}`}
                        className={cx('__festival')}>
                        <Link
                            className={cx('--underline')}
                            href={getFestivalUrl(f)}>
                            <span>{f.name}</span>
                        </Link>
                    </div>
                ))}
            </More>
        </div>
    ) : busy ? (
        <Skeleton
            bones={[
                {
                    customClass: 'mt-2.5 h-3 w-16 md:w-28 md:mt-4',
                    rounded: false,
                },
            ]}
            theme='light'
        />
    ) : (
        <div className={cx('__festivals')}>&nbsp;</div>
    );
};

export default React.memo(TileFestivals);
