import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import IconButton from '@/components/icon/IconButton';
import { IconSize, IconType } from '@/components/icon/Icon';
import { goHome, goSignUp } from '@/common/auth';
import { parseError } from '@/utils/apiErrorHandler';
import { AuthState, selectAuth } from '@/store/auth';
import {
    setShareOpen,
    setShareSource,
    setShareUrl,
    setShareTerm,
} from '@/store/app';
import { UserState, selectUser } from '@/store/user';
import { toggleFollowArtist } from '@/common/artist';
import { toggleFollowFestival } from '@/common/festival';
import { toggleAddToCrate } from '@/common/compare';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import styles from './styles.module.scss';
import Skeleton from '../loading/skeleton/Skeleton';
import { EventProperties, Events, trackEvent } from '@/utils/analytics';

const cx = classNames.bind(styles);

interface Props {
    id: string | number;
    title: string;
    slug: string;
    type: ListItemType;
    shareUrl?: string;
    ticketUrl?: string;
    spotifyUrl?: string;
    ariaLabel: string;
    busy?: boolean;
}

const TileActions: React.FC<Props> = ({
    id,
    title,
    slug,
    type,
    shareUrl,
    ticketUrl,
    spotifyUrl,
    ariaLabel,
    busy = false,
}) => {
    const dispatch = useAppDispatch();
    const { authenticated } = useAppSelector<AuthState>(selectAuth);
    const { userArtists, userFestivals, userCrate } =
        useAppSelector<UserState>(selectUser);

    const [fetching, isFetching] = useState(false);
    const [following, isFollowing] = useState(false);
    const [inCrate, isInCrate] = useState(false);
    const [followingNudge, setFollowingNudge] = useState(false);
    const [comparingNudge, setComparingNudge] = useState(false);

    const signUp = () => goSignUp();

    const onFollowHandler = async () => {
        try {
            isFetching(true);

            const eventProps: EventProperties = {};
            eventProps.authenticated = authenticated;

            if (type === 'festival') {
                eventProps.festival = title;
                eventProps.festivalId = id;
                eventProps.festivalName = slug;
            } else {
                eventProps.artist = title;
                eventProps.artistId = id;
                eventProps.artistName = slug;
            }

            trackEvent(Events.ClickedFollow, eventProps);

            if (!authenticated) {
                signUp();
            } else {
                if (type === 'festival') {
                    await toggleFollowFestival(userFestivals, id, dispatch);
                } else {
                    await toggleFollowArtist(userArtists, id, dispatch);
                }

                setFollowingNudge(true);

                window.setTimeout(() => {
                    setFollowingNudge(false);
                }, 300);
            }
        } catch (error) {
            parseError({
                error,
                onError: [
                    {
                        statusCode: 401,
                        onError: goHome,
                    },
                    {
                        statusCode: 403,
                        onError: goHome,
                    },
                ],
                trackError: false,
                throwError: false,
            });
        } finally {
            isFetching(false);
        }
    };

    const onOpenTicketUrlHandler = () => {
        if (ticketUrl) {
            const eventProps: EventProperties = {};
            eventProps.authenticated = authenticated;

            if (type === 'festival') {
                eventProps.festival = title;
                eventProps.festivalId = id;
                eventProps.festivalName = slug;
            } else {
                eventProps.artist = title;
                eventProps.artistId = id;
                eventProps.artistName = slug;
            }

            trackEvent(Events.ClickedTickets, eventProps);
            window.open(ticketUrl, '_blank', 'noopener');
        }
    };

    const onCompareHandler = async () => {
        try {
            isFetching(true);

            const eventProps: EventProperties = {};
            eventProps.authenticated = authenticated;

            if (type === 'festival') {
                eventProps.festival = title;
                eventProps.festivalId = id;
                eventProps.festivalName = slug;
            } else {
                eventProps.artist = title;
                eventProps.artistId = id;
                eventProps.artistName = slug;
            }

            trackEvent(Events.ClickedCompare, eventProps);

            if (!authenticated) {
                signUp();
            } else {
                await toggleAddToCrate({ id, userCrate, dispatch });

                setComparingNudge(true);

                window.setTimeout(() => {
                    setComparingNudge(false);
                }, 300);
            }
        } catch (error) {
            parseError({
                error,
                onError: [
                    {
                        statusCode: 401,
                        onError: goHome,
                    },
                    {
                        statusCode: 403,
                        onError: goHome,
                    },
                ],
                trackError: false,
                throwError: false,
            });
        } finally {
            isFetching(false);
        }
    };
    const onOpenSpotifyHandler = () => {
        if (spotifyUrl) {
            const eventProps: EventProperties = {
                musicService: 'spotify',
            };

            eventProps.authenticated = authenticated;

            if (type === 'artist') {
                eventProps.artist = title;
                eventProps.artistId = id;
                eventProps.artistName = slug;
            }

            trackEvent(Events.ClickedMusicService, eventProps);
            window.open(spotifyUrl, '_blank', 'noopener noreferrer');
        }
    };

    const onShareHandler = () => {
        const url = `${window.location.origin}${shareUrl}`;

        dispatch(setShareUrl(url));
        dispatch(setShareSource(type));
        dispatch(setShareTerm(slug));
        dispatch(setShareOpen(true));
    };

    useEffect(() => {
        if (type === 'festival' && userFestivals) {
            const exists = userFestivals?.find((f) => f.id === id)
                ? true
                : false;
            isFollowing(exists);
        }
    }, [id, type, userFestivals]);

    useEffect(() => {
        if (type === 'artist' && userArtists) {
            const exists = userArtists?.find((a) => a.id === id) ? true : false;
            isFollowing(exists);
        }
    }, [id, type, userArtists]);

    useEffect(() => {
        if (type === 'festival' && userCrate) {
            const exists = userCrate?.find((f) => f.id === id) ? true : false;
            isInCrate(exists);
        }
    }, [id, type, userCrate]);

    return (
        <>
            <div className={cx('__actions')}>
                {!busy ? (
                    <>
                        {type !== 'post' && id ? (
                            <IconButton
                                ariaLabel={`Follow ${ariaLabel}`}
                                type={
                                    !following
                                        ? IconType.FavoriteOutline
                                        : IconType.Favorite
                                }
                                theme={
                                    !following
                                        ? 'transparent'
                                        : 'outline-primary'
                                }
                                iconSize={IconSize.Small}
                                onPress={onFollowHandler}
                                size={IconSize.Small}
                                nudge={followingNudge}
                                disabled={fetching}
                                disableHover={following}
                                transparent
                            />
                        ) : null}
                        {ticketUrl ? (
                            <IconButton
                                ariaLabel={`Get tickets for ${ariaLabel}`}
                                type={IconType.Ticket}
                                theme='transparent'
                                iconSize={IconSize.Small}
                                onPress={onOpenTicketUrlHandler}
                                size={IconSize.Small}
                                disabled={fetching}
                                transparent
                            />
                        ) : null}
                        {type === 'festival' && id ? (
                            <IconButton
                                ariaLabel={`Compare ${ariaLabel}`}
                                type={
                                    !inCrate
                                        ? IconType.CompareOutline
                                        : IconType.Compare
                                }
                                theme={
                                    !inCrate ? 'transparent' : 'outline-primary'
                                }
                                iconSize={IconSize.Small}
                                onPress={onCompareHandler}
                                size={IconSize.Small}
                                nudge={comparingNudge}
                                disableHover={inCrate}
                                disabled={fetching}
                                transparent
                            />
                        ) : null}
                        <IconButton
                            ariaLabel={`Share ${type} - ${ariaLabel}`}
                            type={IconType.ShareVariant}
                            theme='transparent'
                            iconSize={IconSize.Small}
                            onPress={onShareHandler}
                            size={IconSize.Small}
                            disabled={fetching}
                            transparent
                        />
                        {spotifyUrl ? (
                            <IconButton
                                ariaLabel='Go to spotify profile'
                                type={IconType.Spotify}
                                theme='spotify'
                                iconSize={IconSize.Small}
                                onPress={onOpenSpotifyHandler}
                                size={IconSize.Small}
                                disabled={fetching}
                                transparent
                            />
                        ) : null}
                    </>
                ) : (
                    <Skeleton
                        bones={[{ customClass: 'w-24 h-4 mt-2 mb-1' }]}
                        theme='light'
                    />
                )}
            </div>
        </>
    );
};

export default TileActions;
