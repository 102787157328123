import React from 'react';
import classNames from 'classnames/bind';
import Skeleton, { Bone } from '@/components/loading/skeleton/Skeleton';
import TileFestivals from './TileFestivals';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    id?: string | number;
    title?: string;
    subTitle?: string | GenericElement | (string | GenericElement)[];
    summary?: string;
    type: ListItemType;
    isMobile?: boolean;
    busy?: boolean;
    festivals?: Festival[];
    getFestivalUrl?: (festival: Festival) => string;
}

const TileTextContainer: React.FC<Props> = ({
    id,
    title,
    subTitle,
    summary,
    type,
    isMobile,
    busy = false,
    festivals,
    getFestivalUrl,
}) => {
    const Subtitle = () => {
        if (!subTitle) {
            return <React.Fragment>&nbsp;</React.Fragment>;
        }

        if (typeof subTitle === 'string') {
            return subTitle;
        }

        if (Array.isArray(subTitle)) {
            const maxResults =
                type === 'artist' && isMobile ? 1 : subTitle.length;
            const timestamp = Date.now();
            const list = subTitle.slice(0, maxResults);
            const total = maxResults;

            return list.map((s, idx) => (
                <React.Fragment key={`subtitle-${idx}-${timestamp}`}>
                    {typeof s === 'string' ? s : s()}
                    {total > 1 && idx < total - 1 ? <span>,&nbsp;</span> : null}
                </React.Fragment>
            ));
        }
    };

    const getBones = () => {
        const bones: Bone[] = [];

        if (title || busy) {
            bones.push({
                customClass: 'h-5 md:h-3 w-28 md:w-40 md:mt-1.5',
                rounded: false,
            });
        }

        if (subTitle || (busy && !festivals)) {
            bones.push({
                customClass: 'mt-1 h-3 w-16 md:w-28 md:mt-2.5',
                rounded: false,
            });
        }

        if ((summary || busy) && type !== 'artist') {
            bones.push({
                customClass: 'mt-1 h-3 w-20 md:w-36 md:mt-2',
                rounded: false,
            });
        }

        return bones;
    };

    return (
        <div className={cx('__text-container')}>
            {!busy ? (
                <>
                    {title && <h2 className={cx('__text-title')}>{title}</h2>}
                    {!festivals && (
                        <div className={cx('__text-subtitle')}>
                            <div className={cx('__text-content')}>
                                <Subtitle />
                            </div>
                        </div>
                    )}
                    {summary && type !== 'artist' && (
                        <div className={cx('__text-summary')}>
                            <p className={cx('__text-content')}>{summary}</p>
                        </div>
                    )}
                </>
            ) : (
                <Skeleton bones={getBones()} theme='light' />
            )}
            {festivals && getFestivalUrl && id && (
                <TileFestivals
                    id={id}
                    type={type}
                    festivals={festivals}
                    busy={busy}
                    getFestivalUrl={getFestivalUrl}
                />
            )}
        </div>
    );
};

export default TileTextContainer;
