import $http from '@/utils/http';
import { parseError } from '@/utils/apiErrorHandler';
import { AppStore } from '@/store/index';
import { addUserArtist, setUserArtists } from '@/store/user';
import { setToastData, setToastOpen } from '@/store/app';

export const isFollowingArtist = (userArtists: Artist[], artist: TileProps) => {
    return userArtists.find((a) => a.id === artist.id);
};

export const toggleFollowArtist = async (
    userArtists: Artist[],
    id: number | string,
    dispatch: AppStore['dispatch']
) => {
    const exists = userArtists.find((a) => a.id === id);

    if (!exists) {
        await followArtist(userArtists, id, dispatch);
    } else {
        await unfollowArtist(userArtists, id, dispatch);
    }
};

export const followArtist = async (
    userArtists: Artist[],
    id: number | string,
    dispatch: AppStore['dispatch']
) => {
    try {
        const artist = await $http.post({
            endpoint: '/artists/follow',
            params: {
                artistId: id,
            },
        });

        const exists = userArtists.find((a) => a.id === id);

        if (!exists) dispatch(addUserArtist(artist));

        (document.activeElement as HTMLElement).blur();

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                message: 'Added to your Favorites',
            })
        );
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};

export const unfollowArtist = async (
    userArtists: Artist[],
    id: number | string,
    dispatch: AppStore['dispatch']
) => {
    try {
        await $http.remove({
            endpoint: '/artists/follow',
            params: {
                artistId: id,
            },
        });

        const artists = userArtists.filter((a) => a.id !== id);
        dispatch(setUserArtists(artists));

        (document.activeElement as HTMLElement).blur();

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                message: 'Removed from your Favorites',
            })
        );
    } catch (error) {
        (document.activeElement as HTMLElement).blur();
        parseError({ error });
    }
};
